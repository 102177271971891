import React from "react";
import styled from "styled-components";

const Pagination = ({
  maxPages,
  setCurrentPage,
  currentPage,
  setSubmitted,
}) => {
  const pageNumbers = [];
  for (let i = 0; i < maxPages; i++) {
    pageNumbers.push(i + 1);
  }

  return (
    <Wrapper>
      {pageNumbers.map((number) =>
        number === currentPage ? (
          <PageButton key={number}>{number}</PageButton>
        ) : (
          <PageButton
            key={number}
            inactive
            onClick={() => {
              setCurrentPage(number);
              setSubmitted(true);
            }}
          >
            {number}
          </PageButton>
        )
      )}
    </Wrapper>
  );
};

export default Pagination;

const Wrapper = styled.nav`
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
const PageButton = styled.button`
    background:${(p) => (p.inactive ? "gray" : "green")};
    color:white;
    font-weight:bold;
    padding:4px;
    border-radius:4px;
    font-size 1em;
    display:block;
    width: 32px;
    height: 32px;
    margin-right: 32px;
`;
