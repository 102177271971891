import React, { useState } from "react";
import styled from "styled-components";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { nightOwl } from "react-syntax-highlighter/dist/esm/styles/prism";

export const Wrapper = styled.div`
  margin: 10px;
  width: 20%;
  min-width: 20%;
  background: #0c132e;
  /* padding: 20px; */
`;

const Spancode = styled.span`
  color: yellow;
`;
const SearchWrapper = styled.div`
  color: yellow;
  text-align: left;
  font-size: 30px;
  padding: 12px;
`;
const SearchCommand = styled.div`
  color: yellow;
  text-align: left;
  padding-left: 30px;
  font-size: 30px;
`;
const Comment = styled.div`
  color: lightgreen;
  text-align: left;
  margin-left: 30px;
  font-size: 24px;
  margin-top: 4px;
  background-color: #111736;
  padding: 10px;
`;

const CodeBlock = styled.div`
  margin: 8px;
  border: 2px solid #40158a;
`;

const SearchAggregation = ({
  genre,
  dateStart,
  dateEnd,
  sliderValue,
  searchTerm,
  dirtyCalendar,
}) => {
  let searchObject = getSearchObject(
    searchTerm,
    sliderValue,
    genre,
    dateStart,
    dateEnd,
    dirtyCalendar
  );

  const searchString = JSON.stringify(searchObject, null, 2);

  return (
    <Wrapper>
      {" "}
      <CodeBlock>
        <SearchWrapper>&#123;</SearchWrapper>
        <SearchCommand>$search:</SearchCommand>
        {searchObject.compound && (
          <>
            <Comment>
              {"/* "}
              compound uses{" "}
              <Spancode>must | mustNot | should | filter</Spancode> for clauses
              {" */"}
            </Comment>
          </>
        )}
        <SyntaxHighlighter language="javascript" style={nightOwl}>
          {searchString}
        </SyntaxHighlighter>{" "}
        <SearchWrapper>&#125;</SearchWrapper>
      </CodeBlock>
    </Wrapper>
  );
};

// HELPER FUNCTIONS TO GET SEARCH OBJECTS FOR SEARCH STAGE - BASIC AND COMPOUND
const getSearchObject = (
  searchTerm,
  sliderValue,
  genre,
  dateStart,
  dateEnd,
  dirtyCalendar
) => {
  let searchObject = {
    index: "<INDEXNAME>",
  };
  const mustArray = getMustArray(searchTerm, genre);

  const filterArray = getFilterArray(
    sliderValue,
    dateStart,
    dateEnd,
    dirtyCalendar
  );

  let compoundString = "";
  let compoundObject = {};

  if (mustArray.length > 1 || filterArray.length > 0) {
    // there will be a compound query

    if (mustArray.length > 0) {
      compoundObject.must = mustArray;
    }

    if (filterArray.length > 0) {
      compoundObject.filter = filterArray;
    }
    compoundString = JSON.stringify(compoundObject, null, 2);
  }
  if (compoundString !== "") {
    searchObject.compound = compoundObject;
  } else if (searchTerm !== "") {
    searchObject = {
      index: "<INDEXNAME>",
      text: {
        query: searchTerm,
        path: ["plot", "fullplot", "title"],
        fuzzy: {
          maxEdits: 1.0,
        },
        highlight: { path: "fullplot" },
      },
    };
  } else {
    searchObject = {
      index: "<INDEXNAME>",
      text: {
        query: genre,
        path: "genres",
      },
    };
  }

  return searchObject;
};

const getMustArray = (searchTerm, genre) => {
  let mustArray = [];

  const textObject = {
    text: {
      query: searchTerm,
      path: ["plot", "fullplot", "title"],
      fuzzy: {
        maxEdits: 1.0,
      },
      highlight: { path: "fullplot" },
    },
  };
  console.log("SEARCHTERM: ", searchTerm);
  console.log("TEXT OBJECT: ", textObject);
  if (searchTerm !== "") {
    mustArray.push(textObject);
  }
  const genreObject = {
    text: {
      query: genre,
      path: "genres",
    },
  };
  if (genre?.length !== 0) {
    mustArray.push(genreObject);
  }
  return mustArray;
};

const getFilterArray = (sliderValue, dateStart, dateEnd, dirtyCalendar) => {
  console.log(dirtyCalendar);
  let filterArray = [];

  let calendarObject = {
    range: {
      path: "released",
      gte: new Date(dateStart),
      lte: new Date(dateEnd),
    },
  };

  const ratingObject = {
    range: {
      path: "imdb.rating",
      gte: sliderValue,
      lte: 10,
    },
  };

  if (sliderValue > 0) {
    filterArray.push(ratingObject);
  }

  if (dirtyCalendar) {
    filterArray.push(calendarObject);
  }

  return filterArray;
};

export default SearchAggregation;
